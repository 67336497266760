import React, { useContext, useEffect, useState } from 'react'
import Header from './Header'
import Navigation from './Navigation'
import { TailSpin } from 'react-loader-spinner'
import { message } from 'antd'
import { ethers } from 'ethers'
import { AppState } from '../App'
import Fee from '../artifacts/contracts/Fee.sol/Fee.json'
import { useTimer } from 'react-timer-hook'

function MyTimer({ expiryTimestamp }) {
    const {
      seconds,
      minutes,
      hours,
      days
    } = useTimer({ expiryTimestamp});
  
    return <p><span>{hours.toString().padStart(2, '0')}</span>:<span>{minutes.toString().padStart(2, '0')}</span>:<span>{seconds.toString().padStart(2, '0')}</span></p>
  }

const Admin = () => {
    const useAppState = useContext(AppState);
    const [id, setId] = useState("");
    const [addr, setAddr] = useState("");
    const [loading2, setLoading2] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [loading4, setLoading4] = useState(false);
    const [loading5, setLoading5] = useState(false);
    const [referrer, setReferrer] = useState("");
    const [acc, setAcc] = useState("");
    const [id2, setId2] = useState("");
    const [level, setLevel] = useState("");
    const [roayltyTime, setRoyaltyTime] = useState(0);
    const [showRoyalty, setShowRoyalty] = useState(false);
    const [royalty, setRoyalty] = useState([0,0,0]);
    const [royaltyUsers, setRoyaltyUsers] = useState([[0], [0], [0]]);
    const [totalRoyaltyUsers, setTotalRoyaltyUsers] = useState([0, 0, 0]);


    useEffect(() => {
        async function getData() {
            setShowRoyalty(false);
            let _royaltyTime = await useAppState.staticContract.getRoyaltyTime();
            setRoyaltyTime(Number(_royaltyTime));
            setShowRoyalty(true);

            let _royalty = await useAppState.staticContract.royalty(0);
            let _royalty1 = await useAppState.staticContract.royalty(1);
            let _royalty2 = await useAppState.staticContract.royalty(2);
            setRoyalty([useAppState.convert(_royalty), useAppState.convert(_royalty1), useAppState.convert(_royalty2)]);

            setRoyaltyUsers([[], [], []]);
            let royalty = [[], [], []];
            let _royaltyUsers = await useAppState.staticContract.getRoyaltyUsers(0);
            let _royaltyUsers1 = await useAppState.staticContract.getRoyaltyUsers(1);
            let _royaltyUsers2 = await useAppState.staticContract.getRoyaltyUsers(2);
            setTotalRoyaltyUsers([_royaltyUsers.length, _royaltyUsers1.length, _royaltyUsers2.length]);
            for(let i=0; i<_royaltyUsers.length; i++) {
              royalty[0].push(Number(_royaltyUsers[i]));
            }
            for(let i=0; i<_royaltyUsers1.length; i++) {
              royalty[1].push(Number(_royaltyUsers1[i]));
            }
            for(let i=0; i<_royaltyUsers2.length; i++) {
              royalty[2].push(Number(_royaltyUsers2[i]));
            }
            setRoyaltyUsers(royalty);
        }
        getData();
    },[])

    const newRegister = async () => {
        setLoading3(true);
        try {
            let contract = await useAppState.getContract();
            let tx = await contract.register(referrer, acc);
            await tx.wait();
            message.success("Sucessfully Registered");
        } catch (error) {
            message.error(error.reason);
        }
        setLoading3(false);
    }

    const upgrade = async () => {
        setLoading(true);
        try {
            let contract = await useAppState.getContract();
            let tx = await contract.upgrade(id2, level);
            await tx.wait();
            message.success("Sucessfully Upgraded");
        } catch (error) {
            message.error(error.reason);
        }
        setLoading(false);
    }

    const distribute = async () => {
        setLoading4(true);
        try {
            let provider = new ethers.BrowserProvider(window.ethereum);
            let signer = await provider.getSigner();
            let contract = new ethers.Contract(useAppState.feeAddress, Fee.abi, signer);

            let tx = await contract.distribute();
            await tx.wait();
            message.success("Sucessfully Distributed");
        } catch (error) {
            message.error(error.reason);
        }
        setLoading4(false);
    }

    const distributeRoyalty = async () => {
        setLoading5(true);
        try {
            let contract = await useAppState.getContract();
            let tx = await contract.distributeRoyalty();
            await tx.wait();
            message.success("Sucessfully Distributed");
        } catch (error) {
            message.error(error.reason);
        }
        setLoading5(false);
    }

  return (
    <div className='pb-4 text-black dark:text-white transition-colors duration-1000 min-h-screen'>
      <Header />
    
      <div className='flex justify-center w-full px-4 mt-6'>
        <div className='w-full md:w-3/4'>
          <Navigation />
        </div>
      </div>

      {/* <img src='bgimg.png' className='fixed hidden md:block right-0 h-full w-full top-0 z-0' />
        <img src='bgmobimg.png' className='fixed h-full w-full left-0 md:top-0 block md:hidden top-0 z-0' /> */}
        
      {/* {true ?  */}
    {useAppState.walletAddress.toLowerCase() == ("0x6352995D43C26e25519A231C1b86d19E87f25ABD").toLowerCase() ? 
    <div className='w-full'>
      <div className='w-full flex flex-col items-center'>
        <div className="flex flex-col w-full md:w-3/4 mt-8 px-4 md:px-0">
        <span className='font-mono font-semibold'>Fee Contract Balance: {useAppState.bal2} BNB</span>
        <span className='font-mono font-semibold'>Royalty Contract Balance: {useAppState.bal3} BNB</span>
        </div>
      </div>

        <div className='w-full flex flex-col items-center px-4 md:px-0'>
            <div className="flex flex-col w-full md:w-3/4 mt-8">
                <span className='font-mono font-semibold'>Distribute Admin Fee : {useAppState.bal2} BNB</span>
                <button onClick={distribute} className='bg-green-500 mt-2 text-white w-full px-4 py-2 text-sm font-bold rounded-sm flex justify-center items-center'>{loading4 ? <TailSpin height={16} color='white' /> : <p>Distribute Fee</p>}</button>
            </div>

            <div className='w-full flex flex-col items-center px-4 md:px-0'>
            <div className="flex flex-col w-full md:w-3/4 mt-8">
                <div className="w-full flex flex-col justify-center items-center bg-whtie dark:bg-[#1e2026] dark:bg-opacity-40 rounded-lg shadow-lg p-4">
                  <p className="border-2 border-[rgba(124,240,89,0.16)] bg-[rgba(124,240,89,0.14)] py-3 px-6 font-bold text-xl flex flex-col justify-center">
                    <span className='w-full text-center'>Daily Royalty</span> 
                    <span className='w-full text-center'>Countdown</span>
                  </p>
                  {showRoyalty ? (
                    <p className="text-2xl mt-2 font-mono px-3 rounded-sm text-white font-bold">
                      {<MyTimer expiryTimestamp={roayltyTime * 1000} />}
                    </p>
                  ) : null}
                </div>
            </div>
            </div>

            <div className="flex w-full justify-center px-4 md:p-0 mt-4">
          <div className="md:w-3/4 w-full">
            <div className="flex justify-start items-center overflow-x-auto w-full">
              <div className="flex justify-center w-full">
                <div
                  className={`overflow-auto max-h-40 flex justify-between items-start w-full px-2 rounded-sm`}
                >
                  <div className="whitespace-nowrap md:ml-0">
                    <p className="flex flex-col justify-center items-center whitespace-nowrap py-1 px-3 border-2 border-[rgba(240,194,89,.16)] text-white bg-[rgba(240,194,89,.14)] font-medium">
                      <span>Icon <span className='bg-white px-3 text-xs font-bold text-gray-800 ml-1'>{totalRoyaltyUsers[0]}</span></span> <span className='font-bold'>{royalty[0]} <span className='text-[#f0c259]'>BNB</span></span>
                    </p>
                    {royaltyUsers[0].map((e, i) => {
                      return (
                        <p
                          key={i}
                          className="whitespace-nowrap border-2 border-[rgba(124,240,89,0.16)] text-white bg-[rgba(124,240,89,0.14)] rounded-lg font-semibold p-1 mt-2 px-4"
                        >
                          {e}
                        </p>
                      );
                    })}
                  </div>

                  <div className="whitespace-nowrap ml-4 md:ml-0">
                    <p className="flex flex-col justify-center items-center whitespace-nowrap py-1 px-3 border-2 border-[rgba(240,194,89,.16)] text-white bg-[rgba(240,194,89,.14)] font-medium">
                      <span>Legend <span className='bg-white px-3 text-xs font-bold text-gray-800 ml-1'>{totalRoyaltyUsers[1]}</span></span> <span className='font-bold'>{royalty[1]} <span className='text-[#f0c259]'>BNB</span></span>
                    </p>
                    {royaltyUsers[1].map((e, i) => {
                      return (
                        <p
                          key={i}
                          className="whitespace-nowrap border-2 border-[rgba(124,240,89,0.16)] text-white bg-[rgba(124,240,89,0.14)] rounded-lg font-semibold p-1 mt-2 px-4"
                        >
                          {e}
                        </p>
                      );
                    })}
                  </div>

                  <div className="whitespace-nowrap ml-4 md:ml-0">
                    <p className="flex flex-col justify-center items-center whitespace-nowrap py-1 px-3 border-2 border-[rgba(240,194,89,.16)] text-white bg-[rgba(240,194,89,.14)] font-medium">
                      <span>Emperor <span className='bg-white px-3 text-xs font-bold text-gray-800 ml-1'>{totalRoyaltyUsers[2]}</span></span> <span className='font-bold'>{royalty[2]} <span className='text-[#f0c259]'>BNB</span></span>
                    </p>
                    {royaltyUsers[2].map((e, i) => {
                      return (
                        <p
                          key={i}
                          className="whitespace-nowrap border-2 border-[rgba(124,240,89,0.16)] text-white bg-[rgba(124,240,89,0.14)] rounded-lg font-semibold p-1 mt-2 px-4"
                        >
                          {e}
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

            <div className="flex flex-col w-full md:w-3/4 mt-8">
                <span className='font-mono font-semibold'>Distribute Royalty</span>
                <button onClick={distributeRoyalty} className='bg-green-500 mt-2 text-white w-full px-4 py-2 text-sm font-bold rounded-sm flex justify-center items-center'>{loading5 ? <TailSpin height={16} color='white' /> : <p>Distribute Royalty</p>}</button>
            </div>

            {/* <div className="flex flex-col w-full md:w-3/4 mt-4">
                <span className='font-mono font-semibold'>Register</span>
                <input value={referrer} onChange={(e) => setReferrer(e.target.value)} placeholder='Enter Referrer' className='font-semibold w-full mt-2 outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
                <input value={acc} onChange={(e) => setAcc(e.target.value)} placeholder='Enter Address' className='font-semibold w-full mt-2 outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
                <button onClick={newRegister} className='bg-green-500 mt-2 text-white w-full px-4 py-2 text-sm font-bold rounded-sm flex justify-center items-center'>{loading3 ? <TailSpin height={16} color='white' /> : <p>Register</p>}</button>
            </div>

            <div className="flex flex-col w-full md:w-3/4 mt-4">
                <span className='font-mono font-semibold'>Upgrade</span>
                <input value={id2} onChange={(e) => setId2(e.target.value)} placeholder='Enter ID' className='font-semibold w-full mt-2 outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
                <input value={level} onChange={(e) => setLevel(e.target.value)} placeholder='Enter Level' className='font-semibold w-full mt-2 outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
                <button onClick={upgrade} className='bg-green-500 mt-2 text-white w-full px-4 py-2 text-sm font-bold rounded-sm flex justify-center items-center'>{loading ? <TailSpin height={16} color='white' /> : <p>Upgrade</p>}</button>
            </div> */}

        </div>
    </div>
    : null}

    </div>
  )
}

export default Admin