import React, { useState } from "react";

const Test = () => {
  const [arr, setArr] = useState([
    ["Y"]
    [1, 2],
    [3, 4, 5, 6],
    [7, 8, 9, 10, 11, 12, 13, 14],
    [15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
    [31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62,],
    [63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126],
  ]);

  return (
    <div className="flex flex-col items-center p-4 fixed w-full h-full overflow-x-auto bg-white">
      <div className="flex">
        <div className="p-4 ml-2 rounded-full bg-blue-500 text-white font-bold">
          You
        </div>
      </div>
      <div className="mt-8 flex">
        {arr[1].map((e, i) => {
          return (
            <div key={i} className="p-4 ml-2 rounded-full bg-blue-500 text-white font-bold">
              {e}
            </div>
          )
        })}
      </div>
      <div className="mt-8 flex">
        {arr[2].map((e, i) => {
          return (
            <div key={i} className="p-4 ml-2 rounded-full bg-blue-500 text-white font-bold">
              {e}
            </div>
          )
        })}
      </div>
      <div className="mt-8 flex">
        {arr[3].map((e, i) => {
          return (
            <div key={i} className="p-4 ml-2 rounded-full bg-blue-500 text-white font-bold">
              {e}
            </div>
          )
        })}
      </div>
      <div className="mt-8 flex">
        {arr[4].map((e, i) => {
          return (
            <div key={i} className="p-4 ml-2 rounded-full bg-blue-500 text-white font-bold">
              {e}
            </div>
          )
        })}
      </div>
      <div className="mt-8 flex">
        {arr[5].map((e, i) => {
          return (
            <div key={i} className="p-4 ml-2 rounded-full bg-blue-500 text-white font-bold">
              {e}
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default Test;
