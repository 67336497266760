import React, { useContext, useState, useEffect } from 'react'
import {message, Modal} from 'antd'
import { TailSpin } from 'react-loader-spinner';
import {AppState} from '../App'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ethers } from 'ethers';

const Register = () => {
    const useAppState = useContext(AppState);
    const [searchParams] = useSearchParams();
    const [isModalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [referrer, setReferrer] = useState("");

    useEffect(() => {
        async function getData() {
            let user = await useAppState.staticContract.userInfo(useAppState.id);
            let zeroAddr = 0;
            if(Number(user.referrer) == zeroAddr && useAppState.loaded) {
                setModalOpen(true);
            } else {
                setModalOpen(false);
            }
        }
        getData();
    },[useAppState.walletAddress, useAppState.change, useAppState.id]);

    useEffect(() => {
        let ref = searchParams.get("ref");
        if(ref !== null) {
          setReferrer(ref)
        } else {
          setReferrer("Invalid Referrer Link");
        }
    }, []);

    let OkText = loading ? <TailSpin height={14} color='white' /> : <p>Register</p>;

    const register = async () => {
        setLoading(true);
        try {
            let contract = await useAppState.getContract();
            let tx = await contract.register(referrer, useAppState.walletAddress, {
                value: ethers.parseEther((useAppState.levels[1] + ((useAppState.levels[1] * useAppState.percent[1]) / 100)).toString())
            });
            await tx.wait();
            message.success("Sucessfully Registered");
            useAppState.setChange(useAppState.change + 1);
        } catch (error) {
            message.error(error.reason);
        }
        setLoading(false);
    }

  return (
    <div>
        <Modal title="Register" open={isModalOpen} closable={false} onOk={register} okText={OkText} cancelButtonProps={{className:'hidden'}} >
            <div className='flex w-full flex-col justify-center items-center'>
                <img src='register.png' className='h-24' />
                <p className='text-sm font-medium break-all'>Referrer Id: {referrer == "17534" ? "default Refer" : referrer}</p>
                <p className='text-sm font-medium break-all'>Joining Fee: {useAppState.levels[1] + ((useAppState.levels[1] * useAppState.percent[1]) / 100)} BNB</p>
            </div>
        </Modal>
    </div>
  )
}

export default Register